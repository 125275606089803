import React, { useState, useEffect } from 'react';
import { BsCheck, BsFilter } from 'react-icons/bs';
import Select from "react-select";
import { sweetalert } from '../../App';
import { ExemptionFilter } from '../../types/RecordType';
import { getNullOptions, parseDate, prepareMultiSelect } from '../../util/FormatUtil';
import DatePicker from "react-datepicker";
import moment from 'moment';
import CustomInput from '../form/CustomInput';
import CustomSelect from '../form/CustomSelect';
import CustomDate from '../form/CustomDate';
import CustomPhone from '../form/CustomPhone';
import { customStyles } from '../../util/FormatUtil';


//get filtered object to return

const ExemptionRecordsFilterTabs = (props, ref) => {


    const [filterObj, setFilterObj] = useState<ExemptionFilter>({
        ReqNum: "",
        ChildFirstName: "",
        ChildMiddleInitial: "",
        ChildLastName: "",
        ChildDOB: "",
        NullChildDOB: "",
        ChildBirthCountry: "",
        ChildBirthState: "",
        ChildGenderID: "",
        ChildEthnicityID: "",
        ChildRaceID: "",
        ParentFirstName: "",
        ParentMiddleInitial: "",
        ParentLastName: "",
        MotherMaidenName: "",
        ParentAddress: "",
        ParentCity: "",
        ParentZip: "",
        ParentCounty: "",
        ParentState: "",
        ParentPhone: "",
        NullParentPhone: "",
        SchoolName: "",
        SchoolDistrict: "",
        SchoolYear: "",
        SchoolGrade: "",
        SchoolPhone: "",
        NullSchoolPhone: "",
        ExemptionType: "",
        ContraindicatedImmunizations: "",
        HarmfulCondition: "",
        PhysicianFirstName: "",
        PhysicianLastName: "",
        PhysicianAddress: "",
        PhysicianPhone: "",
        ReligiousLeaderFirstName: "",
        ReligiousLeaderLastName: "",
        ExemptImmunizations: "",
        BeginCreatedDate: "",
        NullCreatedDate: "",
        EndCreatedDate: "",
        Status: [{ label: "Pending", value: null }],
        ExemptionReason: ""
    })
    const [isExemptionFilterActive, setIsExemptionFilterActive] = useState(false)
    const [isChildFilterActive, setIsChildFilterActive] = useState(false)
    const [isParentFilterActive, setIsParentFilterActive] = useState(false)
    const [isSchoolFilterActive, setIsSchoolFilterActive] = useState(false)
    const [isExemptionTabActive, setIsExemptionTabActive] = useState(true)
    const [isChildTabActive, setIsChildTabActive] = useState(false)
    const [isParentTabActive, setIsParentTabActive] = useState(false)
    const [isSchoolTabActive, setIsSchoolTabActive] = useState(false)


    const getFilterObj = () => {
        return {
            ReqNum: filterObj.ReqNum ? filterObj.ReqNum : null,
            ChildFirstName: filterObj.ChildFirstName ? filterObj.ChildFirstName : null,
            ChildMiddleInitial: filterObj.ChildMiddleInitial ? filterObj.ChildMiddleInitial : null,
            ChildLastName: filterObj.ChildLastName ? filterObj.ChildLastName : null,
            ChildDOB: filterObj.ChildDOB ? parseDate(new Date(filterObj.ChildDOB)) : null,
            ChildBirthCountry: filterObj.ChildBirthCountry ? prepareMultiSelect(filterObj.ChildBirthCountry) : null,
            ChildBirthState: filterObj.ChildBirthState ? prepareMultiSelect(filterObj.ChildBirthState) : null,
            ChildGenderID: filterObj.ChildGenderID ? prepareMultiSelect(filterObj.ChildGenderID) : null,
            ChildEthnicityID: filterObj.ChildEthnicityID ? prepareMultiSelect(filterObj.ChildEthnicityID) : null,
            ChildRaceID: filterObj.ChildRaceID ? prepareMultiSelect(filterObj.ChildRaceID) : null,
            ParentFirstName: filterObj.ParentFirstName ? filterObj.ParentFirstName : null,
            ParentMiddleInitial: filterObj.ParentMiddleInitial ? filterObj.ParentMiddleInitial : null,
            ParentLastName: filterObj.ParentLastName ? filterObj.ParentLastName : null,
            MotherMaidenName: filterObj.MotherMaidenName ? filterObj.MotherMaidenName : null,
            ParentAddress: filterObj.ParentAddress ? filterObj.ParentAddress : null,
            ParentCity: filterObj.ParentCity ? filterObj.ParentCity : null,
            ParentZip: filterObj.ParentZip ? filterObj.ParentZip : null,
            ParentCounty: filterObj.ParentCounty ? filterObj.ParentCounty : null,
            ParentState: filterObj.ParentState ? filterObj.ParentState : null,
            ParentPhone: filterObj.ParentPhone ? filterObj.ParentPhone : null,
            School: filterObj.SchoolName ? prepareMultiSelect(filterObj.SchoolName) : null,
            SchoolDistrict: filterObj.SchoolDistrict ? prepareMultiSelect(filterObj.SchoolDistrict) : null,
            SchoolYear: filterObj.SchoolYear ? filterObj.SchoolYear : null,
            SchoolGrade: filterObj.SchoolGrade ? prepareMultiSelect(filterObj.SchoolGrade) : null,
            SchoolPhone: filterObj.SchoolPhone ? filterObj.SchoolPhone : null,
            ExemptionType: filterObj.ExemptionType ? prepareMultiSelect(filterObj.ExemptionType) : null,
            ContraindicatedImmunizations: filterObj.ContraindicatedImmunizations ? prepareMultiSelect(filterObj.ContraindicatedImmunizations) : null,
            HarmfulCondition: filterObj.HarmfulCondition ? filterObj.HarmfulCondition : null,
            PhysicianFirstName: filterObj.PhysicianFirstName ? filterObj.PhysicianFirstName : null,
            PhysicianLastName: filterObj.PhysicianLastName ? filterObj.PhysicianLastName : null,
            PhysicianAddress: filterObj.PhysicianAddress ? filterObj.PhysicianAddress : null,
            PhysicianPhone: filterObj.PhysicianPhone ? filterObj.PhysicianPhone : null,
            ReligiousLeaderFirstName: filterObj.ReligiousLeaderFirstName ? filterObj.ReligiousLeaderFirstName : null,
            ReligiousLeaderLastName: filterObj.ReligiousLeaderLastName ? filterObj.ReligiousLeaderLastName : null,
            ExemptImmunizations: filterObj.ExemptImmunizations ? prepareMultiSelect(filterObj.ExemptImmunizations) : null,
            BeginCreatedDate: filterObj.BeginCreatedDate ? filterObj.BeginCreatedDate : null,
            NullCreatedDate: filterObj.NullCreatedDate ? filterObj.NullCreatedDate : null,
            EndCreatedDate: filterObj.EndCreatedDate ? filterObj.EndCreatedDate : null,
            Status: filterObj.Status ? prepareMultiSelect(filterObj.Status) : null,
            ExemptionReason: filterObj.ExemptionReason ? prepareMultiSelect(filterObj.ExemptionReason) : null
        };
    }

    const dateCheck = (beginDateState, endDateState, beginLabel, endLabel, label) => {
        let valid = true
        if ((beginDateState && !endDateState) || (!beginDateState && endDateState)) {
            sweetalert.fire({
                icon: "error",
                title: "",
                text: `Please enter a valid ${beginLabel} Date and an ${endLabel} Date`,
            });
            valid = false;
        }

        if (beginDateState > endDateState) {
            sweetalert.fire({
                icon: "error",
                title: "",
                text: `${beginLabel} Date cannot be after ${endLabel} Date`,
            });
            valid = false;
        }

        if (beginDateState > new Date() || endDateState > new Date()) {
            sweetalert.fire({
                icon: "error",
                title: "",
                text: `Cannot search for ${label} Date in the future`,
            });
            valid = false;
        }

        if (beginDateState > new Date() || endDateState > new Date()) {
            sweetalert.fire({
                icon: "error",
                title: "",
                text: `Cannot search for ${label} Date in the future`,
            });
            valid = false;
        }
        return valid
    }

    const handleChange = (e, stateToSet, type = 'input', nullStateToSet = null) => {
        if (type === 'input') {
            setFilterObj((prevState) => ({
                ...prevState,
                [stateToSet]: e.target.value
            }))
        }
        else if (type === 'select') {
            setFilterObj((prevState) => ({
                ...prevState,

                [stateToSet]: e
            }))
        }
        else if (type === 'date' || type === 'phone') {
            setFilterObj((prevState) => ({
                ...prevState,
                [stateToSet]: null,
                [nullStateToSet]: e,
            }))
        }

        handleFilteredTab()
    }

    const handleDateOnChange = (value, nullState, state) => {
        if (value) {
            setFilterObj((prevState) => ({
                ...prevState,
                [nullState]: null,
                [state]: value
            }))
        } else {
            setFilterObj((prevState) => ({
                ...prevState,
                [nullState]: null,
                [state]: null
            }))
        }
    }

    //leaving here because this only occurs in the Sample Filter
    const getDateRange = (beginStateToSet, beginState, endStateToSet, endState, nullStateToSet, nullState, label, tooltip, options) => {
        return (
            <div className="form-group row pr-1">
                <fieldset className='col-12 col-md-10 p-2'>
                    <legend style={{ fontSize: "0.8em" }} className={"pt-1 px-1 font-weight-bold"}>{label}</legend>
                    <div className="col-2">
                        {" "}
                        {beginState || endState || nullState ? (
                            <BsCheck className={"float-right"} color={"#21212E"} size={20} />
                        ) : null}
                    </div>
                    <div className={"col-12 pl-0 pr-1"}>
                        <div className="row">
                            <div className={"col-4"}>
                                <label id={`Begin${label.replaceAll(" ", "")}Label`}>Begin: </label>
                            </div>
                            <div className={"col-8 text-lg-right"} id={label} >
                                <DatePicker ariaLabelledBy={`Begin${label.replaceAll(" ", "")}Label`} minDate={new Date("01-01-1000")} maxDate={new Date("12-31-9999")} placeholderText="--/--/----" selected={beginState ? Date.parse(moment(beginState, "MM-DD-YYYY").toISOString()) : null} onChange={(e) => handleDateOnChange(e, nullStateToSet, beginStateToSet)} />
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-4"}>
                                <label id={`End${label.replaceAll(" ", "")}Label`}>End: </label>
                            </div>
                            <div className={"col-8 text-lg-right"} id={label}>
                                <DatePicker ariaLabelledBy={`End${label.replaceAll(" ", "")}Label`} minDate={new Date("01-01-1000")} maxDate={new Date("12-31-9999")} placeholderText="--/--/----" selected={endStateToSet ? Date.parse(moment(endState, "MM-DD-YYYY").toISOString()) : null} onChange={(e) => handleDateOnChange(e, nullStateToSet, endStateToSet)} />
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={label === 'Collection Date' ? "col-12" : "col-12 pl-md-1 pr-md-3 ml-md-2 pr-lg-4"}>
                                <div>
                                    <Select aria-label={label + " Null Filter"} styles={customStyles} isMulti={true} isSearchable={true} placeholder={<div className="accessibilityText">Please Select...</div>} onChange={(e) => { setFilterObj((prevState) => ({ ...prevState, [beginStateToSet]: null, [endStateToSet]: null, [nullStateToSet]: e, })) }} className={"state_select"} options={options} value={nullState} />
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        );
    }

    const handleActiveTab = (tab) => {
        switch (tab) {
            case 'exemption':
                setIsExemptionTabActive(true)
                setIsChildTabActive(false)
                setIsParentTabActive(false)
                setIsSchoolTabActive(false)
                break;
            case 'child':
                setIsExemptionTabActive(false)
                setIsChildTabActive(true)
                setIsParentTabActive(false)
                setIsSchoolTabActive(false)
                break;
            case 'parent':
                setIsExemptionTabActive(false)
                setIsChildTabActive(false)
                setIsParentTabActive(true)
                setIsSchoolTabActive(false)
                break;
            case 'school':
                setIsExemptionTabActive(false)
                setIsChildTabActive(false)
                setIsParentTabActive(false)
                setIsSchoolTabActive(true)
                break;
            default:
                break;
        }
    }

    const handleFilteredTab = () => {

        //Child tab
        // if(!filterObj.PatientFirstName && !filterObj.PatientMiddleName && !filterObj.PatientLastName && !filterObj.PatientEmail && !filterObj.PatientDOB && !filterObj.PatientEthnicityID && !filterObj.PatientRaceID && !filterObj.PatientGenderID && !filterObj.PatientPhone && !filterObj.PatientCity && !filterObj.PatientZip && !filterObj.PatientState && !filterObj.PatientCounty && !filterObj.PatientCountry){
        //     setIsSchoolFilterActive(false)
        // }
        // else{
        //     setIsSchoolFilterActive(true)
        // }
    }

    React.useImperativeHandle(ref, () => ({
        preSubmitChecks() {

            let filteredObj = getFilterObj()
            let keys = Object.keys(filteredObj)
            let hasValue = keys.find((k) => filteredObj[k])
            let dateIsValid = true;

            if (filterObj.BeginCreatedDate || filterObj.EndCreatedDate) {
                dateIsValid = dateCheck(filterObj.BeginCreatedDate, filterObj.EndCreatedDate, "Beginning Created", "Ending Created", "Created");
            }

            if (dateIsValid) {
                return filteredObj
            }
            else {
                return null
            }
        },

        clear() {
            setFilterObj({
                ReqNum: "",
                ChildFirstName: "",
                ChildMiddleInitial: "",
                ChildLastName: "",
                ChildDOB: "",
                NullChildDOB: "",
                ChildBirthCountry: "",
                ChildBirthState: "",
                ChildGenderID: "",
                ChildEthnicityID: "",
                ChildRaceID: "",
                ParentFirstName: "",
                ParentMiddleInitial: "",
                ParentLastName: "",
                MotherMaidenName: "",
                ParentAddress: "",
                ParentCity: "",
                ParentZip: "",
                ParentCounty: "",
                ParentState: "",
                ParentPhone: "",
                NullParentPhone: "",
                SchoolName: "",
                SchoolDistrict: "",
                SchoolYear: "",
                SchoolGrade: "",
                SchoolPhone: "",
                NullSchoolPhone: "",
                ExemptionType: "",
                ContraindicatedImmunizations: "",
                HarmfulCondition: "",
                PhysicianFirstName: "",
                PhysicianLastName: "",
                PhysicianAddress: "",
                PhysicianPhone: "",
                ReligiousLeaderFirstName: "",
                ReligiousLeaderLastName: "",
                ExemptImmunizations: "",
                BeginCreatedDate: "",
                NullCreatedDate: "",
                EndCreatedDate: "",
                Status: "",
                ExemptionReason: ""
            })
        }
    }))



    useEffect(() => {
        handleFilteredTab()
    }, [filterObj])


    return (
        <div className="row">
            <div className="col-12">
                <nav className='d-md-none'>
                    <div className="nav nav-pills nav-fill mb-2" id="nav-tab" role="tablist" style={{ borderBottom: '0.1em solid lightgrey' }}>
                        <a className="nav-item nav-link active col-6 col-md-3" style={{ borderRight: '0.1em solid lightgrey', borderBottom: '0.1em solid lightgrey' }} id="nav-exemption-information-tab" data-toggle="tab" href="#nav-exemption-information" role="tab" aria-controls="nav-exemption-information" aria-selected="true" onClick={() => handleActiveTab('exemption')}>
                            Exemption
                            <BsFilter className={isExemptionFilterActive ? "float-right" : "invisible"} color={isExemptionTabActive ? "aliceblue" : "#21212E"} size={20} />
                        </a>
                        <a className="nav-item nav-link col-6 col-md-3" style={{ borderRight: '0.1em solid lightgrey', borderBottom: '0.1em solid lightgrey' }} id="nav-child-information-tab" data-toggle="tab" href="#nav-child-information" role="tab" aria-controls="nav-child-information" aria-selected="true" onClick={() => handleActiveTab('child')}>
                            Child
                            <BsFilter className={isChildFilterActive ? "float-right" : "invisible"} color={isChildTabActive ? "aliceblue" : "#21212E"} size={20} />
                        </a>
                        <a className="nav-item nav-link col-6 col-md-3" style={{ borderRight: '0.1em solid lightgrey', borderBottom: '0.1em solid lightgrey' }} id="nav-parent-information-tab" data-toggle="tab" href="#nav-parent-information" role="tab" aria-controls="nav-parent-information" aria-selected="false" onClick={() => handleActiveTab('parent')}>
                            Parent
                            <BsFilter className={isParentFilterActive ? "float-right" : "invisible"} color={isParentTabActive ? "aliceblue" : "#21212E"} size={20} />
                        </a>
                        <a className="nav-item nav-link col-6 col-md-3" style={{ borderRight: '0.1em solid lightgrey', borderBottom: '0.1em solid lightgrey' }} id="nav-school-information-tab" data-toggle="tab" href="#nav-school-information" role="tab" aria-controls="nav-school-information" aria-selected="false" onClick={() => handleActiveTab('school')}>
                            School
                            <BsFilter className={isSchoolFilterActive ? "float-right" : "invisible"} color={isSchoolTabActive ? "aliceblue" : "#21212E"} size={20} />
                        </a>
                    </div>
                </nav>
                <nav className='d-none d-md-block'>
                    <div className="nav nav-pills nav-fill mb-2" id="nav-tab" role="tablist" style={{ borderBottom: '0.1em solid lightgrey' }}>
                        <a className="nav-item nav-link active col-6 col-md-3" style={{ borderRight: '0.1em solid lightgrey' }} id="nav-exemption-information-tab" data-toggle="tab" href="#nav-exemption-information" role="tab" aria-controls="nav-exemption-information" aria-selected="true" onClick={() => handleActiveTab('exemption')}>
                            Exemption
                            <BsFilter className={isExemptionFilterActive ? "float-right" : "invisible"} color={isExemptionTabActive ? "aliceblue" : "#21212E"} size={20} />
                        </a>
                        <a className="nav-item nav-link col-6 col-md-3" style={{ borderRight: '0.1em solid lightgrey' }} id="nav-child-information-tab" data-toggle="tab" href="#nav-child-information" role="tab" aria-controls="nav-child-information" aria-selected="true" onClick={() => handleActiveTab('child')}>
                            Child
                            <BsFilter className={isChildFilterActive ? "float-right" : "invisible"} color={isChildTabActive ? "aliceblue" : "#21212E"} size={20} />
                        </a>
                        <a className="nav-item nav-link col-6 col-md-3" style={{ borderRight: '0.1em solid lightgrey' }} id="nav-parent-information-tab" data-toggle="tab" href="#nav-parent-information" role="tab" aria-controls="nav-parent-information" aria-selected="false" onClick={() => handleActiveTab('parent')}>
                            Parent
                            <BsFilter className={isSchoolFilterActive ? "float-right" : "invisible"} color={isSchoolTabActive ? "aliceblue" : "#21212E"} size={20} />
                        </a>
                        <a className="nav-item nav-link col-6 col-md-3" id="nav-school-information-tab" data-toggle="tab" href="#nav-school-information" role="tab" aria-controls="nav-school-information" aria-selected="false" onClick={() => handleActiveTab('school')}>
                            School
                            <BsFilter className={isSchoolFilterActive ? "float-right" : "invisible"} color={isSchoolTabActive ? "aliceblue" : "#21212E"} size={20} />
                        </a>
                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-exemption-information" role="tabpanel" aria-labelledby="nav-exemption-information-tab">
                        {/* exemption information card  */}
                        <div className="row">
                            <div className="col-12 col-md-6 col-xl-4 col-xxl-3 px-5 pl-md-5 pr-md-0">
                                <CustomInput fromFilterPage={true} value={filterObj.ReqNum} label={"Confirmation #"} tooltip={"A patient's unique code tied to their sample"} handleChange={(e) => handleChange(e, "ReqNum")} />
                                <CustomSelect fromFilterPage={true} value={filterObj.ExemptionType} label={"Exemption Type"} tooltip={"Exemption Type"} handleChange={(e) => handleChange(e, "ExemptionType", 'select')} options={props.exemptionTypes} />
                                <CustomSelect fromFilterPage={true} value={filterObj.ExemptImmunizations} label={"Immunizations for Exemption"} tooltip={"Immunizations the exemption was applied for"} handleChange={(e) => handleChange(e, "ExemptImmunizations", 'select')} options={props.immunizations} />
                                <CustomSelect fromFilterPage={true} value={filterObj.ContraindicatedImmunizations} label={"Medically Contraindicated Immunizations"} tooltip={"Medically Contraindicated Immunizations"} handleChange={(e) => handleChange(e, "ContraindicatedImmunizations", 'select')} options={props.immunizations} />
                            </div>
                            <div className="col-12 col-md-6 col-xl-4 col-xxl-3 px-5 pl-md-5 pr-md-0">
                                <CustomInput fromFilterPage={true} value={filterObj.HarmfulCondition} label={"Condition that would endanger child"} tooltip={"A patient's unique code tied to their sample"} handleChange={(e) => handleChange(e, "HarmfulCondition")} />
                                <CustomSelect fromFilterPage={true} value={filterObj.ExemptionReason} label={"Personal Exemption Reason"} tooltip={"Personal Exemption Reason"} handleChange={(e) => handleChange(e, "ExemptionReason", 'select')} options={props.reasons} />
                                <CustomInput fromFilterPage={true} value={filterObj.PhysicianFirstName} label={"Physician First Name"} tooltip={"Physician First Name"} handleChange={(e) => handleChange(e, "PhysicianFirstName")} />
                                <CustomInput fromFilterPage={true} value={filterObj.PhysicianLastName} label={"Physician Last Name"} tooltip={"Physician Last Name"} handleChange={(e) => handleChange(e, "PhysicianLastName")} />
                            </div>
                            <div className="col-12 col-md-6 col-xl-4 col-xxl-3 px-5 pl-md-5 pr-md-0">
                                <CustomInput fromFilterPage={true} value={filterObj.PhysicianAddress} label={"Physician Address"} tooltip={"Physician Address"} handleChange={(e) => handleChange(e, "PhysicianAddress")} />
                                <CustomInput fromFilterPage={true} value={filterObj.PhysicianPhone} label={"Physician Office Phone"} tooltip={"Physician Office Phone"} handleChange={(e) => handleChange(e, "PhysicianPhone")} />
                                <CustomInput fromFilterPage={true} value={filterObj.ReligiousLeaderFirstName} label={"Religious Leader/ Legal Guardian First Name"} tooltip={"Religious Leader/ Legal Guardian First Name"} handleChange={(e) => handleChange(e, "ReligiousLeaderFirstName")} />
                                <CustomInput fromFilterPage={true} value={filterObj.ReligiousLeaderLastName} label={"Religious Leader/ Legal Guardian Last Name"} tooltip={"Religious Leader/ Legal Guardian Last Name"} handleChange={(e) => handleChange(e, "ReligiousLeaderLastName")} />
                            </div>
                            <div className="col-12 col-md-6 col-xl-4 col-xxl-3 px-5 pl-md-5 pr-md-0">
                                <CustomSelect fromFilterPage={true} value={filterObj.Status} label={"Exemption Status"} tooltip={"Status of Exemption"} handleChange={(e) => handleChange(e, "Status", 'select')} options={[{ label: "Pending", value: null }, { label: "Denied", value: 0 }, { label: "Approved", value: 1 }]} />
                                {getDateRange("BeginCreatedDate", filterObj.BeginCreatedDate, "EndCreatedDate", filterObj.EndCreatedDate, "NullCreatedDate", filterObj.NullCreatedDate, "Created Date", "The date that a patient record was created using the Appointment or Submission form.", getNullOptions())}
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-child-information" role="tabpanel" aria-labelledby="nav-child-information-tab">
                        {/* child information card  */}
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-4 px-5 pl-md-5 pr-md-0">
                                <CustomInput fromFilterPage={true} value={filterObj.ChildFirstName} label={"First Name"} tooltip={`Child First Name`} handleChange={(e) => handleChange(e, "ChildFirstName",)} />
                                <CustomInput fromFilterPage={true} value={filterObj.ChildMiddleInitial} label={"Middle Initial"} tooltip={`Child Middle Initial`} handleChange={(e) => handleChange(e, "ChildMiddleInitial",)} />
                                <CustomInput fromFilterPage={true} value={filterObj.ChildLastName} label={"Last Name"} tooltip={`Child Last Name`} handleChange={(e) => handleChange(e, "ChildLastName")} />
                                <CustomDate fromFilterPage={true} value={filterObj.ChildDOB} nullValue={filterObj.NullChildDOB} label={"Date Of Birth"} tooltip={`The date of birth of the Child`} handleDateOnChange={(e) => handleDateOnChange(e, "NullChildDOB", "ChildDOB")} handleChange={(e) => handleChange(e, "ChildDOB", "date", "NullChildDOB")} options={getNullOptions()} />
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 px-5 pl-md-5 pr-md-0">
                                <CustomSelect fromFilterPage={true} value={filterObj.ChildBirthState} label={"Birth State"} tooltip={`The state of the Child's birth`} handleChange={(e) => handleChange(e, "ChildBirthState", 'select')} options={props.states} />
                                <CustomSelect fromFilterPage={true} value={filterObj.ChildBirthCountry} label={"Birth Country"} tooltip={`The country of the Child's Birth`} handleChange={(e) => handleChange(e, "ChildBirthCountry", 'select')} options={props.countries} />
                                <CustomSelect fromFilterPage={true} value={filterObj.ChildEthnicityID} label={"Ethnicity"} tooltip={`The ethnicity of the Child`} handleChange={(e) => handleChange(e, "ChildEthnicityID", 'select')} options={props.ethnicity} />
                                <CustomSelect fromFilterPage={true} value={filterObj.ChildRaceID} label={"Race"} tooltip={`The race of the Child`} handleChange={(e) => handleChange(e, "ChildRaceID", 'select')} options={props.race} />
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 px-5 pl-md-5 pr-md-0">
                                <CustomSelect fromFilterPage={true} value={filterObj.ChildGenderID} label={"Gender"} tooltip={`The gender of the Child`} handleChange={(e) => handleChange(e, "ChildGenderID", 'select')} options={props.gender} />
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-parent-information" role="tabpanel" aria-labelledby="nav-parent-information-tab">
                        {/* parent information card  */}
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-4 px-5 pl-md-5 pr-md-0">
                                <CustomInput fromFilterPage={true} value={filterObj.ParentFirstName} label={"First Name"} tooltip={"The first name of the Parent"} handleChange={(e) => handleChange(e, "ParentFirstName")} />
                                <CustomInput fromFilterPage={true} value={filterObj.ParentMiddleInitial} label={"Middle Initial"} tooltip={"The middle initial of the Parent"} handleChange={(e) => handleChange(e, "ParentMiddleInitial")} />
                                <CustomInput fromFilterPage={true} value={filterObj.ParentLastName} label={"Last Name"} tooltip={"The last name of the Parent"} handleChange={(e) => handleChange(e, "ParentLastName")} />
                                <CustomInput fromFilterPage={true} value={filterObj.MotherMaidenName} label={"Mother Maiden Name"} tooltip={"The Child's Mother's maiden name"} handleChange={(e) => handleChange(e, "MotherMaidenName")} />
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 px-5 pl-md-5 pr-md-0">
                                <CustomInput fromFilterPage={true} value={filterObj.ParentAddress} label={"Address"} tooltip={"Parent's Address of Residence"} handleChange={(e) => handleChange(e, "ParentAddress")} />
                                <CustomInput fromFilterPage={true} value={filterObj.ParentCity} label={"City"} tooltip={"The city of the Parent's address"} handleChange={(e) => handleChange(e, "ParentCity")} />
                                <CustomSelect fromFilterPage={true} value={filterObj.ParentState} label={"State"} tooltip={`The state of the Parent's address`} handleChange={(e) => handleChange(e, "ParentState", 'select')} options={props.states} />
                                <CustomInput fromFilterPage={true} value={filterObj.ParentCounty} label={"County"} tooltip={"The county of the Parent's address"} handleChange={(e) => handleChange(e, "ParentCounty")} />
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 px-5 pl-md-5 pr-md-0">
                                <CustomInput fromFilterPage={true} value={filterObj.ParentZip} label={"Zip"} tooltip={"The zipcode of the Parent's address"} handleChange={(e) => handleChange(e, "ParentZip")} />
                                <CustomPhone fromFilterPage={true} value={filterObj.ParentPhone} nullValue={filterObj.NullParentPhone} label={"Phone"} tooltip={`The phone number of the Patient`} handleDateOnChange={(e) => handleDateOnChange(e, "NullParentPhone", "ParentPhone")} handleChange={(e) => handleChange(e, "ParentPhone", "phone", "NullParentPhone")} options={getNullOptions()} />
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-school-information" role="tabpanel" aria-labelledby="nav-school-information-tab">
                        {/* school information card  */}
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-6 px-5 pl-md-5 pr-md-0">
                                <CustomSelect fromFilterPage={true} value={filterObj.SchoolDistrict} label={"District"} tooltip={`School District`} handleChange={(e) => handleChange(e, "SchoolDistrict", 'select')} options={props.districts} />
                                <CustomSelect fromFilterPage={true} value={filterObj.SchoolName} label={"Name"} tooltip={`School Name`} handleChange={(e) => handleChange(e, "SchoolName", 'select')} options={props.schools} />
                                <CustomSelect fromFilterPage={true} value={filterObj.SchoolGrade} label={"Grade"} tooltip={`School Grade`} handleChange={(e) => handleChange(e, "SchoolGrade", 'select')} options={props.grades} />
                                <CustomPhone fromFilterPage={true} value={filterObj.SchoolPhone} nullValue={filterObj.NullSchoolPhone} label={"Phone"} tooltip={`The phone number of the school`} handleDateOnChange={(e) => handleDateOnChange(e, "NullSchoolPhone", "SchoolPhone")} handleChange={(e) => handleChange(e, "SchoolPhone", "phone", "NullSchoolPhone")} options={getNullOptions()} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.forwardRef(ExemptionRecordsFilterTabs)